import "./programma.scss";
import * as dompack from 'dompack';
import getTid from "@mod-tollium/js/gettid";

class cProgrammaFilter
{
  constructor(form)
  {
    form.addEventListener("submit", ev => {
      ev.preventDefault();
    });

    this.maxitems = document.querySelector(".programmaoverview").dataset.maxitems;

    this.morebtn = document.querySelector(".programmaoverview .showmoreresults");
    if( this.morebtn )
      this.morebtn.children[0].addEventListener("click", ev => this.showMore() );

    this.baseurl = document.location.href.split(/[?#]+/)[0];

    this.countnode = form.querySelector(".programma-filterform__count");
    this.themefilter = form.querySelector(".programma-filterform__themefilter select");
    if( this.themefilter )
      this.themefilter.addEventListener("change", ev => this.doFilter());

    this.items = document.querySelectorAll(".performancelist .performance-item");
    this.itemcount = this.items.length;

    let thema = this.getUrlParam("thema");
    if( thema != "" && this.themefilter && this.themefilter.querySelector("option[value='" + thema + "']")  )
      this.themefilter.value = thema;

    this.doFilter();
  }

  showMore()
  {
    let i = 0;
    let c = 0;
    for( let node of this.items )
    {
      if( !node.classList.contains("hide") )
        ++c;

      if( node.style.display == "none")
      {
        ++i;
        node.style.display = "";
        if( i >= this.maxitems - 1 )
          break;
      }
    }

    this.afterUpdate();

    this.morebtn.style.display = c >= this.itemcount ? "none" : "";
  }

  getUrlParam(name)
  {
    let urlparamstr = location.search.replace(/\+/g,"%20");
    let val  = ( new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)') ).exec(urlparamstr);
    return val ? decodeURIComponent(val[1]) : "";
  }

  afterUpdate()
  { //load previously hidden images
    dompack.dispatchCustomEvent(window, "pakhuisoost:refreshcontent", { bubbles    : true
                                                                      , cancelable : false
                                                                      , detail     : {}
                                                                      });
  }

  doFilter()
  {
    this.itemcount = 0;
    let i = 0;
    for( let node of this.items )
    {
      node.style.display = i < this.maxitems ? "" : "none"; // keep items hidden after maxitems (pagination)

      if( !this.themefilter || !this.themefilter.value || node.dataset.theme == this.themefilter.value )
      {
        node.classList.remove("hide");
        ++this.itemcount;
      }
      else
        node.classList.add("hide");

      ++i;
    }

    this.afterUpdate();

    this.countnode.textContent = getTid('concordia:webdesigns.pakhuisoost.frontend.js.nractivities', this.itemcount);

    if( this.morebtn )
      this.morebtn.style.display = this.maxitems > this.itemcount - 1 ? "none" : "";

    if( history.replaceState )
    {
      let urlparam = [];
      if( this.getUrlParam("archief") == "1" )
        urlparam.push("archief=1");
      if( this.themefilter && this.themefilter.value )
         urlparam.push("thema=" + this.themefilter.value);
      history.replaceState(null, "", this.baseurl + ( urlparam.length ? "?" + urlparam.join("&") : "" ) );
    }
  }
}

dompack.register(".programma-filterform > form", node => new cProgrammaFilter(node));

import "./header.scss";
import * as dompack from 'dompack';

class cHeaderPosition
{
  constructor(node)
  {
    this.node = node;
    this.navlist = this.node.querySelector(".mainnav div > ul");
    this.logo = this.node.querySelector(".logo");

    this.isfixed = document.documentElement.classList.contains("fixedmenu");
    this.ismobile = false;

    if( window.event_supportspassive )
      window.addEventListener("scroll", ev => this.checkPosition(ev), { passive: true } );
    else
      window.addEventListener("scroll", ev => this.checkPosition(ev) );

    this.navlist.addEventListener("click", ev => {
      if( !document.documentElement.classList.contains("mobilelayout") )
        return;

      let togglebtn = dompack.closest(ev.target,".togglechildren");
      if( !togglebtn )
        return;

      dompack.toggleClass(togglebtn.parentNode, "showchildren", togglebtn.classList.contains("togglebtn--show"));
    });

    window.addEventListener("load", ev => this.checkPosition(ev) );
    window.addEventListener("resize", ev => this.checkPosition(ev) );
    this.checkPosition();
  }

  checkPosition(ev)
  {
    if( !ev || ev.type == "resize" || ev.type == "load" )
    { //switch desktop/mobile layout depends on if desktop menu items fit in window
      let win_x = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let win_y = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      if( !this.viewport || win_x != this.viewport.x || (ev && ev.type == "load") )
      { //check for mobile layout
        this.navlist.parentNode.insertBefore(this.logo, this.navlist);
        document.documentElement.classList.remove("mobilelayout");
        this.navlist.clientWidth;//force css update

        this.ismobile = this.navlist.getBoundingClientRect().right > win_x - 30;//30px padding
        if( this.ismobile )
          document.documentElement.classList.add("mobilelayout");
      }

      this.viewport = { x : win_x
                      , y : win_y
                      };

      if( this.ismobile )
        this.node.appendChild(this.logo);
    }

    if( this.ismobile )
      return;

    let pos = this.node.getBoundingClientRect();

    let dofixed = pos.bottom <= 0;
    if( dofixed && !this.isfixed )
      document.documentElement.classList.add("fixedmenu");
    else if( !dofixed && this.isfixed)
      document.documentElement.classList.remove("fixedmenu");

    this.isfixed = dofixed;
  }
}

function showMobileMenu()
{
  document.body.addEventListener("keyup", hideMobileMenu);
  document.documentElement.classList.add("showmobilemenu");
}
function hideMobileMenu(ev)
{
  if( ev && ev.type == "keyup" && ev.keyCode != 27 )
    return;

  document.body.removeEventListener("keyup", hideMobileMenu);
  document.documentElement.classList.remove("showmobilemenu");
}

function toggleMobileMenu(node)
{
  node.addEventListener("keyup", ev => {
    if( ev.keyCode == 13 && !document.documentElement.classList.contains("showmobilemenu"))
      showMobileMenu();
  });
  node.addEventListener("click", ev => {
    if( document.documentElement.classList.contains("showmobilemenu") )
      hideMobileMenu();
    else
      showMobileMenu();
  });
}

dompack.register("header .menutoggle", node => toggleMobileMenu(node));
dompack.register("header", node => new cHeaderPosition(node));

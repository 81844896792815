import "./faq.scss";
import * as dompack from 'dompack';

class cFaq
{
  constructor(node)
  {
    this.duration = 300;//ms, see css

    node.addEventListener("click", ev => {
      let toggle = dompack.closest(ev.target,".widget-faq__item__toggle");
      if( toggle )
        this.toggle(toggle.parentNode);
    });
  }

  toggle( node )
  {
    let container = node.querySelector(".widget-faq__item__foldcontainer");
    clearTimeout(this.timer);

    if( node.classList.contains("active") )
    { //hide
      container.style.height = container.clientHeight + "px";
      node.classList.remove("active");
      container.clientHeight;//force css update
      container.style.height = "";
    }
    else
    { //show
      container.style.height = container.children[0].clientHeight + "px";
      node.classList.add("active");
      this.timer = setTimeout(() => {
        container.style.height = "";//fallback to css auto incase of resizing window
      }, this.duration);
    }
  }
}

dompack.register(".widget-faq", node => new cFaq(node));
